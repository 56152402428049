import { createWebHistory, createRouter } from "vue-router";
import Home from "./components/Home.vue";
import Login from "./components/Login.vue";
import Register from "./components/Register.vue";
import TypeForm from "./components/TypeForm.vue";
import store from '@/store';
// lazy-loaded
const Profile = () => import("./components/Profile.vue")
const ProfileAdmin = () => import("./components/ProfileAdmin.vue")
const ListAdmins = () => import("@/views/adm/List.vue")
const BoardAdmin = () => import("./components/BoardAdmin.vue")
const BoardModerator = () => import("./components/BoardModerator.vue")
const BoardUser = () => import("./components/BoardUser.vue")
const SolicitationList = () => import("@/components/leads/SolicitationList.vue")
const SolicitationEdit = () => import("@/components/leads/SolicitationEdit.vue")
const CompanyList = () => import("@/components/company/CompanyList.vue")
const CompanyCreate = () => import("@/components/company/CreateCompany.vue")
const CompanyEdit = () => import("@/components/company/EditCompany.vue")
const CompanyView = () => import("@/components/company/ViewCompany.vue")
const SellerList = () => import("@/components/sellers/SellerList.vue")
const RegisterSeller = () => import("@/components/sellers/RegisterSeller.vue")
const RegisterSellerAdmin = () => import("@/components/sellers/RegisterSellerAdm.vue")
const EditSeller = () => import("@/components/sellers/EditSeller.vue")
const ViewSeller = () => import("@/components/sellers/ViewSeller.vue")
const Chat = () => import("@/views/ChatGanbei.vue")
const Reports = () => import("@/views/Reports.vue")
const Financial = () => import("@/views/Financial.vue")
const ListCredits = () => import("@/views/credits/List.vue")
const TransactionCredits = () => import("@/views/credits/Transaction.vue")
const BuyCredit = () => import("@/views/credits/Buy.vue")
const FinancialView = () => import("@/views/financial/View.vue")
const ResetPassword = () => import("@/components/ResetPassword.vue")
const RegisterAdm = () => import("@/views/adm/Register.vue")
const UserEdit = () => import("@/views/adm/Edit.vue")
const SeguroAuto = () => import("@/views/clientForm/Auto.vue")
const SeguroSaude = () => import("@/views/clientForm/Saude.vue")
const SeguroSaudePJ = () => import("@/views/clientForm/SaudePJ.vue")

//const Typeform = () => import("@/views/Typeform.vue")

const routes = [
  {
    path: "/",
    name: "home",
    component: Login,
  },
  // {
  //   path: "/envia",
  //   //name: "typeform",
  //   component: TypeForm,
  // },
  // {
  //   path: "/home",
  //   component: Home,
  // },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/register",
    name: "register",
    component: Register,
  },
  {
    path: "/register-adm",
    name: "registeradm",
    component: RegisterAdm,
  },
  {
    path: "/meus-dados",
    name: "profile",
    // lazy-loaded
    component: Profile,
  },
  {
    path: "/meus-dados",
    name: "profileadm",
    // lazy-loaded
    component: ProfileAdmin,
  },
  {
    path: "/admin",
    name: "admin",
    // lazy-loaded
    component: BoardAdmin,
  },
  {
    path: "/dashboard",
    name: "moderator",
    // lazy-loaded
    component: BoardModerator,
  },
  {
    path: "/user",
    name: "user",
    // lazy-loaded
    component: BoardUser,
  },
  {
    path: "/administradores",
    name: "list-admins",
    // lazy-loaded
    component: ListAdmins,
  },
  {
    path: "/administradores/cadastrar",
    name: "register-admins",
    // lazy-loaded
    component: RegisterAdm,
  },
  {
    path: "/administradores/editar",
    name: "users-edit",
    component: UserEdit,
  },
  //
  // LEADS
  //
  {
    path: "/solicitacoes",
    name: "solicitations",
    component: SolicitationList,
  },
  {
    path: "/solicitacoes/:id",
    name: "solicitation-edit",
    component: SolicitationEdit,
  },

  //
  // COMPANIES
  //
  {
    path: "/corretoras",
    name: "companies",
    component: CompanyList,
  },
  {
    path: "/corretoras/visualizar/:id",
    name: "companies-view",
    component: CompanyView,
  },
  {
    path: "/corretoras/criar",
    name: "companies-create",
    component: CompanyCreate,
  },
  {
    path: "/corretoras",
    name: "companies-edit",
    component: CompanyEdit,
  },
  //
  // SELLERS
  //
  {
    path: "/usuarios",
    name: "sellers",
    // lazy-loaded
    component: SellerList,
  },
  {
    path: "/usuario/cadastrar",
    name: "register-seller",
    // lazy-loaded
    component: RegisterSeller,
  },
  {
    path: "/usuario/adm-cadastrar",
    name: "register-seller-admin",
    // lazy-loaded
    component: RegisterSellerAdmin,
  },
  {
    path: "/editar-usuario/:id",
    name: "seller-edit",
    // lazy-loaded
    component: EditSeller,
  },
  {
    path: "/usuario/visualizar/:id",
    name: "seller-view",
    // lazy-loaded
    component: ViewSeller,
  },
  {
    path: '/chat',
    name: 'chat',
    component: Chat,
  },
  //FINANCIAL
  {
    path: '/financeiro',
    name: 'financeiro',
    component: Financial,
  },
  {
    path: '/financeiro/visualizar-pagamento/:id',
    name: 'financeiro-view',
    component: FinancialView,
  },
  //CREDITS
  {
    path: '/creditos',
    name: 'creditos',
    component: ListCredits,
  },
  {
    path: '/comprar',
    name: 'buy-credit',
    component: BuyCredit,
  },
  {
    path: '/transacoes',
    name: 'transaction-credits',
    component: TransactionCredits,
  },
  {
    path: '/relatorios',
    name: 'relatorios',
    component: Reports,
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: ResetPassword,
  },
  {
    path: '/cotar-seguro/auto',
    name: 'seguro-auto',
    component: SeguroAuto,
  },
  {
    path: '/cotar-seguro/saude',
    name: 'seguro-saude',
    component: SeguroSaude,
  },
  {
    path: '/cotar-seguro/saude-empresarial',
    name: 'seguro-saude-pj',
    component: SeguroSaudePJ,
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// router.beforeEach((to, from, next) => {
//   const publicPages = ['/login', '/register', '/home'];
//   const authRequired = !publicPages.includes(to.path);
//   const loggedIn = localStorage.getItem('user');

//   // trying to access a restricted page + not logged in
//   // redirect to login page
//   if (authRequired && !loggedIn) {
//     next('/login');
//   } else {
//     next();
//   }
// });

router.beforeEach((to, from, next) => {
  const publicPages = ['/', '/login', '/register', '/home', '/uploads', '/reset-password', '/cotar-seguro/auto', '/cotar-seguro/saude', '/cotar-seguro/saude-empresarial'];
  const sellerPages = ['/login', '/register', '/user', '/chat', '/meus-dados'];
  const authRequired = !publicPages.includes(to.path);
  const authSeller = !sellerPages.includes(to.path);
  const loggedIn = localStorage.getItem('user');

  if (to.path === '/reset-password' && to.query.token) {
    next();
  }
  
  // const loggedInTime = store._state.data.auth.status.loggedInTime;
  // const expirationTime = 1 * 60 * 1000; // 30 minutes (adjust as needed)

  // console.log("tempo", loggedInTime);
  // console.log("tempo 2", new Date() - loggedInTime);
  // console.log("tempo 3", expirationTime);

  // //Check session time
  // if (!publicPages && !loggedInTime) {
  //   localStorage.removeItem('user');
  //   console.log("tempo aqui");
  //   // User is not logged in, redirect to the login page
  //   next('/login');
  // } else if (loggedInTime && new Date() - loggedInTime > expirationTime) {
  //   console.log("tempo elseif");
  //   console.log("tempo elseif tempo 1", new Date() - loggedInTime);
  //   console.log("tempo elseif tempo 2", expirationTime);

  //   store.auth.dispatch('logout') // Assuming you have a logout action in your store
  //     .then(() => {
  //       next('/login');
  //       loggedIn = null
  //       console.log("dispatchhhhh", loggedIn);
  //     });
    
  // } 
  
  // trying to access a restricted page + not logged in
  // redirect to login page
  
  if (authRequired && !loggedIn) {
    next('/login');
  } else {
    if(loggedIn) {
      // console.log("entrou aqui");
      const loggedInParse = JSON.parse(localStorage.getItem('user'));
      const loggedInRole = loggedInParse.roles
      // console.log("dispatch 1", loggedInParse);
      // console.log("dispatch 2", loggedInRole);
      if (authSeller && loggedInRole == "3") {
        // console.log("opa")
        next('/login');
      } else {
        next();
      }
    }
    next();
  }
  
  

});

// router.beforeEach((to, from, next) => {
//   const publicPages = ['/', '/login', '/register', '/home'];
//   const sellerPages = ['/', '','/login', '/register', '/user', '/chat'];
//   const authRequired = !publicPages.includes(to.path);
//   const authSeller = !sellerPages.includes(to.path);
//   const loggedIn = localStorage.getItem('user');
//   //const roleAdmin = JSON.parse(localStorage.getItem('user')).roles

  

//   // trying to access a restricted page + not logged in
//   // redirect to login page
//   if (!loggedIn) {
//     next('/login');
//     console.log("OPA")
//   }

//   if (loggedIn) {
//     if (authSeller && loggedIn.roles == "3") {
//       next('/login');
//     } else {
//       next();
//     }
//   }

  
  
//   // if (authRequired && !loggedIn) {
//   //   next('/login');
//   // } else {
//   //   next();
//   // }
// });

export default router;